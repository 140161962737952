<template>
  <div>
    <my-search-form
      :foldButton="true"
      :formData="searchFormData"
      @updateFormData="updateFormData"
      @submitSearch="submitSearch"
    >
    </my-search-form>
    <div class="bg-container">
      <div class="content">
        <div class="top-menu">
          <div class="menu-item" @click="showExpressDialog">
            <el-image
              style="width: 50px; height: 50px"
              src="http://cdnprinttool.xingyebao.com/dyPrintTool/images/expressbill_setting.png"
              fit="fill"
            ></el-image>
            <span>快递单设置</span>
          </div>
          <div class="menu-item" @click="showFahuoDialog">
            <el-image
              style="width: 50px; height: 50px"
              src="http://cdnprinttool.xingyebao.com/dyPrintTool/images/Invoice_setting.png"
              fit="fill"
            ></el-image>
            <span>发货单设置</span>
          </div>
        </div>
        <!-- 新手指导 -->
        <!-- NewUserShow -->
        <NewUser v-if="NewUserShow" v-bind:setType="setType"/>
        <!-- 未查询时的缺省 -->
        <div v-if="dataType" class="noDataClass">
          <div style="justify-content: left;width: 200px;flex-wrap: wrap;cursor: pointer;" @click="textJump">
            <div>
              <p></p>
              <p>如何使用抖音电子面单？</p>
            </div>
            <div>
              <p></p>
              <p>如何使用电子面单打印？</p>
            </div>
          </div>
        </div>
        <!-- component占位 -->
        <component :is="isShow"></component>
        <div class="serviceBox" v-if="NewUserShow" :style="showHiddenData=='显示'?'right:-20px':'right:20px'">
          <img class="service_bg_two" src="http://cdnprinttool.xingyebao.com/dyPrintTool/images/service_photo.png" alt="">
          <span class='service_bg_span' style="bottom: 48px;" @click="handleService">客服</span>
          <span class='service_bg_span' @click="handleShowHidden" style="bottom: 12px;">{{showHiddenData}}</span>
          <img v-if="bigtext" class="service_bgtext" @click="handleShowHidden" src="http://cdnprinttool.xingyebao.com/dyPrintTool/images/clickBig.png" alt="">
          <div class="service_qr" v-if="serviceShow">
            <i class="el-icon-close service_qr_close" @click="handleService"></i>
            <p class="service_qr_text1">咨询客服</p>
            <p class="service_qr_text2">微信扫码联系客服</p>
            <div style="text-align: center;">
              <img class="service_qr_img" src="http://cdnprinttool.xingyebao.com/dyPrintTool/images/erweima.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 发货单设置弹窗 -->
    <template>
      <my-dialog-2
        :centerDialogVisible="dialogFahuoVisible"
        @updateVisible="updateFahuoVisible"
        @openDialog="handleOpenFahuoDialog"
        @print="doPrintFahuo"
      >
        <div class="fahuo-dialog">
          <div class="left-iframe">
            <!-- 发货单预览弹窗 -->
            <iframe id="fahuo_iframe" width="800px" height="560px">
              <p>当前浏览器不支持iframe标签</p>
            </iframe>
          </div>
          <div class="right-menu">
            <el-button @click="FahuoGlobalSettings" class="menu-btn1" size="mini">全局设置</el-button>
            <!-- <ul class="radio-list">
              <li class="radio-item">
                <span style="margin-left: 10px">纸张样式</span>
              </li>
              <el-radio-group
                v-model="activeFahuoIndex"
                @change="handleRadioChange"
              >
                <el-radio
                  style="margin:5px 0px;"
                  v-for="(item, index) in fahuoTemplateList"
                  :key="index"
                  :label="index + ''"
                  >{{ item.express_name }}</el-radio
                >
              </el-radio-group>
            </ul>
            <el-button
              class="menu-btn2"
              type="primary"
              size="mini"
              @click="setFahuoTemplate"
              >确认</el-button
            > -->
          </div>
        </div>
      </my-dialog-2>
      <!-- 发货单全局设置 -->
      <my-dialog-5
        dialogTitle="发货单"
        :centerDialogVisible="dialogFahuoGlobalSettingsVisible"
        @updateVisible="updateFahuoGlobalSettingsVisible"
        @resetPopupData="updateFahuoGlobalSettingsVisible(false)"
        @submitPopupData="saveFahuoGlobalSettingsDialog"
      >
        <template slot="content">
          <div class="ExpressGlobalSettingsBox">
            <div class="ExpressGlobalSettingsBoxLeft">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <!-- <span style="color:#fff;font-size: 14px;">1</span> -->
                <span style="color: #f39f27;margin-left:10px;">发货单设置</span>
              </div>
              <div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">发货单标题</div>
                  <input v-model="info_Invoice.title" class="FahuoGlobalSettingsItemInput" type="text" placeholder="发货单标题"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">经办人</div>
                  <input v-model="info_Invoice.operator" class="FahuoGlobalSettingsItemInput" type="text" placeholder="经办人"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">店铺名称</div>
                  <input v-model="info_Invoice.shop_name" class="FahuoGlobalSettingsItemInput" type="text" placeholder="店铺名称"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">联系电话</div>
                  <input v-model="info_Invoice.phone" onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="11" class="FahuoGlobalSettingsItemInput" type="text" placeholder="联系电话"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">发件地址</div>
                  <input v-model="info_Invoice.address" class="FahuoGlobalSettingsItemInput" type="text" placeholder="发件地址"/>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">友情提示</div>
                  <textarea v-model="info_Invoice.tips" class="FahuoGlobalSettingsItemTextarea" type="text" placeholder="友情提示"/>
                </div>
              </div>
              <!-- <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">2</span>
                <span style="color: #f39f27;margin-left:10px;">默认字体字号</span>
              </div> -->
              <!-- <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">默认字体</div>
                  <el-select v-model="FahuoGlobalSettingsFontFamily" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="FahuoGlobalSettingsItemName">默认字号</div>
                  <el-select v-model="FahuoGlobalSettingsFontSize" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select v-model="FahuoGlobalSettingsFontWeight" size="mini" style="width:80px;margin-left:20px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <div class="FahuoGlobalSettingsItemTips">注意：默认字号或加粗将影响所有模版，太大或太小都可能导致打印不全，请谨慎调试</div>
                </div>
              </div> -->
            </div>
            <!-- <div class="ExpressGlobalSettingsBoxRight"> -->
              <!-- <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">3</span>
                <span style="color: #f39f27;margin-left:10px;">打印整体缩放</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span style="color: #999999;">横向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="FahuoGlobalSettingsScaleZoom_Transverse" :show-tooltip="false"></el-slider>
                <span style="color: #999999;">{{FahuoGlobalSettingsScaleZoom_Transverse}}%</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span style="color: #999999;">纵向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="FahuoGlobalSettingsScaleZoom_Longitudinal" :show-tooltip="false"></el-slider>
                <span style="color: #999999;">{{FahuoGlobalSettingsScaleZoom_Longitudinal}}%</span>
              </div> -->
            <!-- </div> -->
          </div>
        </template>
      </my-dialog-5>
    </template>
    
    <!-- 快递单设置弹窗 -->
    <template>
      <my-dialog-2
        :centerDialogVisible="dialogExpressVisible"
        @updateVisible="updateExpressVisible"
        @openDialog="handleOpenExpressDialog"
        @print="doPrintExpress"
      >
        <div class="express-dialog">
          <el-tabs
            v-model="expressTemplate"
            type="border-card"
            :before-leave="beforeLeave"
          >
            <el-tab-pane
              style="width: 1080px; height: 560px"
              v-for="(item, index) in expressTemplateList"
              :key="index"
              :label="item.express_name"
              :name="index + ''"
            >
              <div class="express-pro-box">
                <!-- 快递模板预览区 -->
                <!-- <iframe :id="'00' + index" width="800px" height="560px">
                  <p>当前浏览器不支持iframe标签</p>
                </iframe> -->
                <div class="preview-express-box">
                  <img style="width: 443px;" :src="expressTemplateList[activeExpressIndex].image_url" >
                </div>
                <!-- 属性编辑区 -->
                <div class="ExpressAttrBox">
                  <!-- <span class="ExpressAttrTitle">设置发货网点</span>
                  <div>
                    使用淘宝账号授权 
                    <a style="color: #3165ce" :href="TaobaoAuthURL" target="__b">新增店铺授权</a>
                    <div @click="GetWaybillSearch" class="RefreshAuthorization">授权后点我刷新</div>
                  </div> -->
                  <div>
                    <!-- <div class="ExpressAttrShopName">
                      {{get_shop_info.shop_name}}
                      <span @click="CancelAuth" class="DeleteShop">删除账号</span>
                    </div> -->
                    <template v-if="WaybillSite.length!=0">
                      <template v-for="(item,index) in WaybillSite">
                        <div :key="'WaybillSite'+index">
                          <div class="WaybillSiteAddress">{{item.province}}{{item.city}}{{item.district}}({{item.branch_code}})</div>
                          <div class="WaybillSiteNumber">可用单号{{item.quantity}}/已用单号{{item.allocated_quantity}}</div>
                          <div class="WaybillSiteInfoBox">
                            <el-radio @change="WaybillSiteInfoItemChange" v-model="WaybillSiteInfoItemSelected" class="WaybillSiteInfoItem" :label="item.id" >
                              <div class="WaybillSiteInfoItemRight">
                                <div class="WaybillSiteInfoItemText">{{item.province}}{{item.city}}{{item.district}}{{item.detail}}</div>
                                <div v-if="item.is_default" class="WaybillSiteInfoItemTips">使用中</div>
                              </div>
                            </el-radio>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div>
                        <div class="ExpressAttrShopOpenServiceTips">当前店铺尚未开通此快递的电子面单服务</div>
                        <a href="https://fuwu.jinritemai.com/detail?service_id=21770&from=gy" target="_blank" class="ExpressAttrShopOpenServiceLink">申请开通</a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="express-tab-btn-box">
            <!-- <div @click="openAddExpressVisible" class="btn-container">
              <i class="el-icon-plus"></i>
              <el-button type="text" size="mini" class="lable-btn">添加模板</el-button>
            </div> -->
            <div @click="modifyExpressTemplateSequence" class="btn-container">
              <el-button type="text" size="mini" class="lable-btn">调整顺序</el-button>
            </div>
            <!-- <div @click="ExpressGlobalSettings" class="btn-container">
              <el-button type="text" size="mini" class="lable-btn">全局设置</el-button>
            </div> -->
          </div>
          <div class="modifyExpressTemplateSequenceBox" v-if="expressTemplateListTemp">
            <div class="ExpressTemplateModifySequenceBox">
              <div v-for="(item,index) in expressTemplateListTemp" :key="index"  class="ExpressTemplateModifySequenceLabel" :style="{backgroundColor:SelectedExpressTemplateModifySequenceLabel==index?'#fff':''}">
                <template v-if="SelectedExpressTemplateModifySequenceLabel==index">
                  <div @click="ExpressTemplateModifySequenceLabelBtn(index,index-1)" class="ExpressTemplateModifySequenceLabelLeft iconfont icon-arrow-left-bold"></div>
                  <div @click="ExpressTemplateModifySequenceLabelBtn(index,index+1)" class="ExpressTemplateModifySequenceLabelRight iconfont icon-arrow-right-bold"></div>
                  <!-- <div @click="ExpressTemplateModifySequenceLabelDelete(index)" class="ExpressTemplateModifySequenceLabelDelete iconfont icon-close-bold"></div> -->
                </template>
                <div @click="SelectedExpressTemplateModifySequenceLabelFN(index)" class="ExpressTemplateModifySequenceLabelText" :title="item.express_name">{{item.express_name}}</div>
              </div>
            </div>
            <div class="ExpressTemplateSaveSequenceBox">
              <el-button type="warning" size="mini" class="ExpressTemplateSaveSequenceBtn" @click="ExpressTemplateSaveFN">保存修改</el-button>
              <el-button type="info" size="mini" class="ExpressTemplateSaveSequenceBtn" @click="ExpressTemplateCancelFN">取消</el-button>
            </div>
          </div>
        </div>
      </my-dialog-2>
      <!-- 添加模板弹窗 -->
      <my-dialog-4
        :dialogTitle="'快递单'"
        :centerDialogVisible="dialogAddExpressTemplateVisible"
        @updateVisible="updateAddExpressVisible"
        @resetPopupData="updateAddExpressVisible(false)"
        @submitPopupData="saveAddExpressDialog"
      >
        <template slot="content">
          <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
            <span>添加快递公司</span>
          </div>
          <div class="content-subcontent" style="padding: 5px 0">
            <el-select v-model="addExpressId" placeholder="快递公司" size="mini">
              <el-option
                v-for="(item, index) in expressList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </template>
      </my-dialog-4>
      <!-- 全局设置 -->
      <my-dialog-5
        :dialogTitle="'快递单'"
        :centerDialogVisible="dialogExpressGlobalSettingsVisible"
        @updateVisible="updateExpressGlobalSettingsVisible"
        @resetPopupData="updateExpressGlobalSettingsVisible(false)"
        @submitPopupData="saveExpressGlobalSettingsDialog"
      >
        <template slot="content">
          <div class="ExpressGlobalSettingsBox">
            <div class="ExpressGlobalSettingsBoxLeft">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">1</span>
                <span style="margin-left:10px;">快递单设置</span>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认快递</div>
                  <el-select v-model="addExpressId" placeholder="快递公司" size="mini">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">现有快递</div>
                  <div class="ExpressGlobalSettingsItemAllSetKDList">
                    <div class="ExpressGlobalSettingsItemAllSetKDListItem">
                      抖音中通一联单
                      <i class="iconfont icon-close-bold ExpressGlobalSettingsItemAllSetKDListItemClose"></i>
                    </div>
                  </div>
                </div>
                <div @click="ExpressGlobalSettingsItemAllSetKDListItemAddExpress" class="ExpressGlobalSettingsItemAllSetKDListItemAddExpress">+ 添加快递单</div>
              </div>
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">2</span>
                <span style="margin-left:10px;">默认字体字号</span>
              </div>
              <div class="content-subcontent" style="padding: 5px 0">
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认字体</div>
                  <el-select v-model="ExpressGlobalSettingsFontFamily" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <div class="ExpressGlobalSettingsLabel">
                  <div class="ExpressGlobalSettingsItemName">默认字号</div>
                  <el-select v-model="ExpressGlobalSettingsFontSize" size="mini" style="width:80px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select v-model="ExpressGlobalSettingsFontWeight" size="mini" style="width:80px;margin-left:20px;">
                    <el-option
                      v-for="(item, index) in expressList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <div class="ExpressGlobalSettingsItemTips">注意：默认字号或加粗将影响所有模版，太大或太小都可能导致打印不全，请谨慎调试</div>
                </div>
              </div>
            </div>
            <div class="ExpressGlobalSettingsBoxRight">
              <div class="content-subheader" style="padding: 5px 0; color: #b4cad9">
                <span style="color:#fff;font-size: 14px;">3</span>
                <span style="margin-left:10px;">打印整体缩放</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span>横向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="ExpressGlobalSettingsScaleZoom_Transverse" :show-tooltip="false"></el-slider>
                <span>{{ExpressGlobalSettingsScaleZoom_Transverse}}%</span>
              </div>
              <div class="ExpressGlobalSettingsZoomBox">
                <span>纵向缩放</span>
                <el-slider style="width:190px;margin: 0px 10px;" v-model="ExpressGlobalSettingsScaleZoom_Longitudinal" :show-tooltip="false"></el-slider>
                <span>{{ExpressGlobalSettingsScaleZoom_Longitudinal}}%</span>
              </div>
            </div>
          </div>
        </template>
      </my-dialog-5>
    </template>
    <!-- 查询进度条的弹窗 -->
    <progressBarPopup v-if="progressBarPopup!==false" :data="progressBarPopup" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { lodopPreview } from "@/utils/functions.js";
import myDialog2 from "@/UI/myDialog2";
import myDialog4 from "@/UI/myDialog4";
import myDialog5 from "@/UI/myDialog5";
import mySearchForm from "@/UI/mySearchForm";
import progressBarPopup from "@/UI/progressBarPopup";
import index from "@/components/batchprint/index";
import ResultList from "@/components/batchprint/ResultList";
import NewUser from "@/components/newUser/index";

export default {
  components: {
    myDialog2,
    myDialog4,
    myDialog5,
    mySearchForm,
    index,
    ResultList,
    NewUser,
    progressBarPopup,
  },
  data() {
    return {
      serviceShow:true,
      bigtext:false,
      showHiddenData:'隐藏',
      activeFahuoIndex: "0",
      // 默认的渲染窗口
      activeExpressIndex: "0",
      dialogFahuoVisible: false,
      dialogExpressVisible: false,
      dialogAddExpressTemplateVisible: false,
      dialogExpressGlobalSettingsVisible: false,
      expressTemplateListTemp: null,
      SelectedExpressTemplateModifySequenceLabel:0,
      ExpressGlobalSettingsFontFamily:"宋体",
      ExpressGlobalSettingsFontSize:"14",
      ExpressGlobalSettingsFontWeight:"加粗",
      ExpressGlobalSettingsScaleZoom_Transverse: 50, // 快递单横向缩放
      ExpressGlobalSettingsScaleZoom_Longitudinal: 50, // 快递单纵向缩放


      // 发货单全局设置
      FahuoGlobalSettingsDialog: true,
      dialogFahuoGlobalSettingsVisible: false,
      FahuoGlobalSettingsFontFamily:"宋体",
      FahuoGlobalSettingsFontSize:"14",
      FahuoGlobalSettingsFontWeight:"加粗",
      FahuoGlobalSettingsScaleZoom_Transverse:50, // 发货单横向缩放
      FahuoGlobalSettingsScaleZoom_Longitudinal:50, // 发货单横向缩放
      setType: [false, false, false],
      NewUserShow: false,
      WaybillSiteInfoItemSelected: "", // 默认选中快递网点索引
    };
  },
  mounted() {
    this.searchFormData_temp = JSON.parse(JSON.stringify(this.searchFormData));
    this.$store.dispatch("batchprint/get_search_result",true);
    this.$store.dispatch("batchprint/GetTaobaoAuthURL");
    this.$store.dispatch("print/getFieldData")
    this.$store.commit("batchprint/SET_ISSHOW", "");
    this.$store.dispatch("setting/getConfig").then((res) => {
      // this.setType[0] = false
      // this.setType[1] = this.info_Invoice.ctime ?true :false
      this.setType[0] = res.data.express_list.length > 0 ?true :false
      this.setType[1] = true
      this.setType[2] = (this.PRINTERLIST && window.douyin_print_list) ?true :false
      // this.contentShow = true
      if(this.setType.indexOf(false) !== -1) {
        this.NewUserShow = true
        this.$store.commit("batchprint/SET_DATATYPE", false);
      }else {
        this.NewUserShow = false
        this.$store.commit("batchprint/SET_DATATYPE", true);
      }
      console.log(this.setType)
    })
  },
  computed: {
    ...mapState({
      dataType: (state) => state.batchprint.dataType,
      isShow: (state) => state.batchprint.isShow,
      searchFormData: (state) => state.batchprint.searchFormData,
      expressTemplateList: (state) => state.print.expressTemplateList,
      expressList: (state) => state.print.expressList,
      fahuoTemplateList: (state) => state.print.fahuoTemplateList,
      // 打印参数
      printOrderInfo: (state) => state.batchprint.printOrderInfo,
      printExpressTemplate: (state) => state.batchprint.printExpressTemplate,
      printExpressBgimg: (state) => state.batchprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.batchprint.printFahuoBgimg,
      progressBarPopup:(state) => state.batchprint.progressBarPopup,
      TaobaoAuthURL: (state)=> state.batchprint.TaobaoAuthURL,
      WaybillSite: (state)=> state.batchprint.WaybillSite,
      get_shop_info: (state)=> state.setting.get_shop_info.data,
      info_Invoice: (state) => state.setting.invoice_list,
      express_list: (state) => state.setting.express_list,
    }),
    addExpressId: {
      get() {
        return this.$store.state.print.addExpressId;
      },
      set(val) {
        this.$store.commit("print/SET_ADD_EXPRESS_ID", val);
      },
    },
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val+'');
      }
    }
  },
  methods: {
    handleService(){
      this.serviceShow=!this.serviceShow
    },
    handleShowHidden(){
      if (this.showHiddenData=='隐藏') {
        this.showHiddenData='显示'
        this.bigtext=true
      }else{
        this.showHiddenData='隐藏'
        this.bigtext=false
      }
    },


    // searchForm函数
    // 更新表单数据
    updateFormData(val) {
      console.log(val,'---val');
      this.searchFormData = val;
    },
    // 点击查询按钮
    submitSearch() {
      this.NewUserShow = false
      this.$store.commit("batchprint/SAVE",{is_shipped:0})
      this.searchFormData.pull_data = 1 // 标识是否拉取新数据
      this.$store.dispatch("batchprint/get_search_result").then(res=>{
        this.searchFormData.pull_data = 0 // 使用完成后还原
      });
      this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
      this.$store.commit("batchprint/SET_DATATYPE", false);
      
    },
    // 打印函数
    doPrintExpress() {
      this.$message.error("请使用下方打印按钮进行打印！");
      // if(this.printOrderInfo.length == 0){
      //   this.$message.error("请选择要打印的订单");
      // }
      // else {
      //   lodopSinglePrinta(this.LODOP, '1', this.printOrderInfo, this.fahuoConsigner, this.printExpressBgimg, this.printExpressTemplate );
      // }
    },
    doPrintFahuo() {
      this.$message.error("请使用下方打印按钮进行打印！");
      // if(this.printOrderInfo.length == 0){
      //   this.$message.error("请选择要打印的订单");
      // }
      // else {
      //   lodopSinglePrinta(this.LODOP, '2', this.printOrderInfo, this.fahuoConsigner, this.printFahuoBgimg, this.printFahuoTemplate);
      // }
    },
    updateFahuoVisible(val) {
      this.dialogFahuoVisible = val;
    },
    handleOpenFahuoDialog() {
      lodopPreview(
        this.LODOP,
        "5",
        "fahuo_iframe",
        this.printOrderInfo,
        "",
        "",
        "",
      );
    },
    handleRadioChange(lable) {
      this.activeFahuoIndex = lable;
      lodopPreview(
        this.LODOP,
        "5",
        "fahuo_iframe",
        this.printOrderInfo,
        "",
        "",
        ""
      );
    },
    setFahuoTemplate() {
      this.dialogFahuoVisible = false;
    },
    showExpressDialog() {
      this.dialogExpressVisible = true;
      // this.$store.dispatch('print/get_express_template');
    },
    // 弹窗渲染完成加载第一个模板
    handleOpenExpressDialog() {
      this.beforeLeave(this.activeExpressIndex)
    },
    updateExpressVisible(val) {
      this.dialogExpressVisible = val;
    },
    // tab切换时提前触发的函数
    beforeLeave(activeIndex) {
      this.activeExpressIndex = activeIndex;
      this.GetWaybillSearch()
    },
    // 弹出添加快递模板弹窗
    openAddExpressVisible(){
      // 注意顺序
      this.updateExpressGlobalSettingsVisible(false)
      this.updateExpressVisible(false)
      this.dialogAddExpressTemplateVisible = true;
    },
    // 添加快递模板弹窗
    updateAddExpressVisible(val) {
      this.showExpressDialog()
      this.dialogAddExpressTemplateVisible = val;
    },
    saveAddExpressDialog() {
      this.$store.dispatch("print/add_express_template");
      this.dialogAddExpressTemplateVisible = false;
    },
    // 修改订单模板顺序
    modifyExpressTemplateSequence(){
      this.SelectedExpressTemplateModifySequenceLabel = this.expressTemplate
      this.expressTemplateListTemp = JSON.parse(JSON.stringify(this.expressTemplateList))
    },
    // 保存订单模板顺序的修改
    ExpressTemplateSaveFN(){
      let id = []
      this.expressTemplateListTemp.map(item=>{
        id.push(item.id)
      })
      this.$store.dispatch("batchprint/UpdateSortTemplate",{id});

      this.$store.commit("print/SET_EXPRESS_TEMPLATE_LIST",this.expressTemplateListTemp);
      this.ExpressTemplateCancelFN()
      this.expressTemplate = this.SelectedExpressTemplateModifySequenceLabel
    },
    // 取消修改
    ExpressTemplateCancelFN(){
      this.expressTemplateListTemp=null
    },
    // 选择要修改顺序的快递
    SelectedExpressTemplateModifySequenceLabelFN(index){
      this.SelectedExpressTemplateModifySequenceLabel = index
    },
    // 修改顺序
    ExpressTemplateModifySequenceLabelBtn(old_index,new_index){
      if(new_index<0||new_index>=this.expressTemplateListTemp.length)return false;
      let temp_list = JSON.parse(JSON.stringify(this.expressTemplateListTemp))
      let temp_Data = this.expressTemplateListTemp[old_index]
      temp_list[old_index] = temp_list[new_index]
      temp_list[new_index] = temp_Data
      this.expressTemplateListTemp = temp_list
      this.SelectedExpressTemplateModifySequenceLabelFN(new_index)
    },
    // 删除快递
    ExpressTemplateModifySequenceLabelDelete(index){
      let temp_list = this.expressTemplateListTemp.filter((n,m)=>{
        return index != m
      })
      if(this.SelectedExpressTemplateModifySequenceLabel >= temp_list.length){
        this.SelectedExpressTemplateModifySequenceLabel = temp_list.length-1
      }
      this.expressTemplateListTemp = temp_list
    },
    // 快递模板全局设置
    ExpressGlobalSettings(){
      this.updateExpressVisible(false)
      this.dialogExpressGlobalSettingsVisible = true
    },
    updateExpressGlobalSettingsVisible(val){
      this.showExpressDialog()
      this.dialogExpressGlobalSettingsVisible = val
    },
    saveExpressGlobalSettingsDialog(){
      // this.$store.dispatch("");
      this.dialogExpressGlobalSettingsVisible = false
    },
    ExpressGlobalSettingsItemAllSetKDListItemAddExpress(){
      this.openAddExpressVisible()
    },
    // 发货全局设置弹窗
    showFahuoDialog(){
      this.dialogFahuoVisible = true
    },
    // 高级设置
    advancedSetting() {

    },
    FahuoGlobalSettings(){
      this.updateFahuoVisible(false)
      this.dialogFahuoGlobalSettingsVisible = true
    },
    updateFahuoGlobalSettingsVisible(val){
      this.showFahuoDialog()
      this.dialogFahuoGlobalSettingsVisible = val
    },
    saveFahuoGlobalSettingsDialog(){
      this.$store.dispatch("setting/editAddressInvoice",this.info_Invoice);
      this.dialogFahuoGlobalSettingsVisible = false
    },
    FahuoGlobalSettingsItemAllSetKDListItemAddFahuo(){
      this.openAddFahuoVisible()
    },
    textJump() {
      let path = `${window.location.protocol}//${window.location.host}/#/usetutorial`
      window.open(path);
    },
    // 获取网点
    GetWaybillSearch(){
      this.$store.dispatch("batchprint/GetWaybillSearch",{cp_code: this.expressTemplateList[this.activeExpressIndex].company_no }).then(res=>{
        if(res.data){
          res.data.map(item=>{
            if(item.is_default){
              this.WaybillSiteInfoItemSelected = item.id
            }
          })
        }
      });
    },
    // 修改使用网点
    WaybillSiteInfoItemChange(val){
      this.WaybillSite.map(item=>{
        if(item.id == this.WaybillSiteInfoItemSelected){
          item.is_default = 1
        }
      })
      this.$store.dispatch("batchprint/set_default_branch",{id: this.WaybillSiteInfoItemSelected})
    },
    CancelAuth(){
      this.$store.dispatch("batchprint/CancelAuth").then(res=>{
        this.GetWaybillSearch() // 取消授权后重新获取网点信息
      })
    }
  }
};
</script>

<style lang="less" scoped>
.serviceBox {
    z-index: 2000;
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 256px;
    height: 237px;
    .service_qr {
      width: 261px;
      height: 339px;
      position: absolute;
      bottom: 0;
      right: 67px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      pointer-events: auto;
    .service_qr_close {
      width: 9px;
      height: 9px;
      color: #afafaf;
      margin-left: 235px;
      margin-top: 12px;
      cursor: pointer;
    }
    .service_qr_text1 {
      margin: 0;
      margin-top: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #333;
      text-align: center;
    }
    .service_qr_text2 {
      margin: 0;
      margin-top: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #767989;
      text-align: center;
    }
    .service_qr_img {
      width: 203px;
      height: 203px;
      margin-top: 25px;
      text-align: center;
    }
  }
  .service_bg_two {
    height: 125px;
    width: 47px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .service_bg_span {
    position: absolute;
    right: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    color: #767989;
    pointer-events: auto;
  }
  .service_bgtext {
    width: 76px;
    height: 28.5px;
    position: absolute;
    bottom: 97.5px;
    cursor: pointer;
    pointer-events: auto;
    right: 34px;
    z-index: 2;
  }
}



.bg-container {
  min-height: 500px;
  background: linear-gradient(to bottom, #e6e0d0 0%, #ffffff 100%);
  padding: 10px;
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;
}
.content {
  position: relative;
  width: 100%;
  min-width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  padding-bottom: 0px;
}
.top-menu {
  position: absolute;
  top: 20px;
  right: 50px;
  display: flex;
  flex-direction: row;
  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    color: #c6bb9d;
    cursor: pointer;
  }
}
.express-pro-box{
  display: flex;
}
// 重写快递单设置标签栏样式
/deep/ .express-dialog .el-tabs__header{
  width: 500px;
  border-bottom: none;
}
/deep/ .express-dialog .el-tabs__nav-prev{
  background: #333333;
  font-size: 12px;
  width: 18px;
  text-align: center;
  border-radius: 5px 0px 5px 0px;
}
/deep/ .express-dialog .el-tabs__nav-next{
  background: #333333;
  font-size: 12px;
  width: 18px;
  text-align: center;
  border-radius: 0px 5px 0px 5px;
}
.express-tab-btn-box{
  position: absolute;
  top: -40px;
  height: 40px;
  left: 510px;
  display: flex;
  background-color: #333;
  border-radius: 5px 5px 0 0;
}
.btn-container{
  margin: auto 10px;
  position: relative;
}
.btn-container:not(:first-child)::before{
  content: "|";
  position: absolute;
  left: -10px;
}
.modifyExpressTemplateSequenceBox{
  position: absolute;
  top: -40px;
  background-color: #4f4f4f;
  z-index: 3;
  width: 1080px;
}
.ExpressTemplateSaveSequenceBox{
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  width: 130px;
}
.ExpressTemplateSaveSequenceBtn{
  width: 80px;
  margin: 5px 0px;
}
.ExpressTemplateModifySequenceBox{
  font-size: 12px;
  padding: 8px 5px;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  width: 950px;
  min-height: 100px;
  background-color: #333;
  color: #ebb563;
}
.ExpressTemplateModifySequenceLabel{
  height: 32px;
  line-height: 32px;
  margin: 5px 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.ExpressTemplateModifySequenceLabelLeft{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 5px 0px 0px 5px;
  left: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelRight{
  position: absolute;
  background-color: #dfdfdf;
  border-radius: 0px 5px 5px 0px;
  right: -20px;
  width: 20px;
  user-select: none;
}
.ExpressTemplateModifySequenceLabelText{
  height: 100%;
  padding: 0px 20px;
}
.ExpressTemplateModifySequenceLabelDelete{
  position: absolute;
  right: 0px;
  color: #999999;
  font-weight: bold;
  width: 20px;
  font-size: 12px;
  top: 1px;
}
.ExpressGlobalSettingsBox{
  // display: flex;
  height: 100%;
}
.ExpressGlobalSettingsBoxLeft{
  height: 430px;
  // height: 475px;
  // width: 390px;
  // border-right: 1px solid #6b6e76;
}
.ExpressGlobalSettingsLabel{
  margin-bottom: 14px;
  padding-left: 42px;
}
.ExpressGlobalSettingsItemName{
  display: inline-block;
  margin-right: 20px;
}
.FahuoGlobalSettingsItemName{
  display: inline-block;
  width: 70px;
  text-align: end;
  margin-right: 20px;
  color: #999999;
}
.ExpressGlobalSettingsItemAllSetKDList{
  height: 120px;
  overflow-y: auto;
  padding-left: 4px;
  width: 250px;
  display: inline-block;
  vertical-align: top;
}
.ExpressGlobalSettingsItemAllSetKDListItem{
  background: #eee;
  border-radius: 2px;
  color: #425b6d;
  float: left;
  line-height: 20px;
  margin: 0 10px 10px 0;
  min-height: 20px;
  padding: 0 14px 0 10px;
  position: relative;
}
.ExpressGlobalSettingsItemAllSetKDListItemClose{
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 2px;
}
.ExpressGlobalSettingsItemAllSetKDListItemAddExpress{
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin: 4px 0 0 112px;
  padding-left: 18px;
  text-decoration: none;
  width: 80px;
  border-radius: 20px;
  background-color: #f39f27;
}
.ExpressGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #fff;
}
.FahuoGlobalSettingsItemTips{
  margin: 20px 20px 0px 0px;
  color: #999999;
}
.ExpressGlobalSettingsBoxRight{
  padding-left: 28px;
  width: 390px;
}
.ExpressGlobalSettingsZoomBox{
  display:flex;
  align-items: center;
  padding-left: 42px;
}
.ExpressAttrBox{
  flex: 1;
  padding: 30px 20px 0;
  background: #4e5159;
}
.ExpressAttrTitle{
  color: #eb9e26;
  font-size: 12px;
  line-height: 32.94px;
  margin: 10px 0px;
}
.ExpressAttrShopName{
  border-bottom: 1px solid #6e7178;
  padding: 0 0 8px;
  color: #fff;
  margin: 20px 0 4px;
}
.ExpressAttrShopOpenServiceTips{
  margin-top: 10px;
  color: #adadad;
  font-size: 12px;
}
.ExpressAttrShopOpenServiceLink{
  margin: 10px 0px;
  color: #b9cede;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.FahuoGlobalSettingsItemInput{
  width: 200px;
  padding: 2px 5px;
  outline:none;
  font-size: 12px;
}
.FahuoGlobalSettingsItemTextarea{
  padding: 2px 5px;
  font-size: 12px;
  outline:none;
  width: 202px;
  height: 48px;
  resize: none;
  vertical-align: top;
}
.RefreshAuthorization{
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  background: #f6891e;
  margin-top: 10px;
}
.noDataClass {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  div {
    display: flex;
    align-items: center;
    p:nth-child(1) {
      width: 5px;
      height: 5px;
      background-color: #f5821f;
      border-radius: 50%;
      margin-right: 10px;
    }
    p:nth-child(2) {
      color: #333;
      font-size: 16px;
      line-height: 16px;
    }
  }
  div:nth-child(2) {
    padding-top: 15px;
  }
}
.DeleteShop{
  color: #bdcfde;
  font-size: 14;
  text-decoration: underline;
  cursor: pointer;
}
.WaybillSiteAddress{
  margin-top: 10px;
  color: #9c9a9c;
}
.WaybillSiteNumber{
  margin-top: 10px;
  color: #adaead;
}
.WaybillSiteInfoBox{
  margin-top: 10px;
  background: #394142;
  border: #313839;
  border-radius: 5px;
  padding: 10px;
}
.WaybillSiteInfoItem{
  display: flex;
}
.WaybillSiteInfoItemRight{
  width: 190px;
  line-height: 1.5;
}
.WaybillSiteInfoItemText{
  word-break:break-all;
  white-space:pre-wrap;
  color: #9c9a9c;
}
.WaybillSiteInfoItemTips{
  color: #00ba9c;
}
.preview-express-box{
  width:800px;height:560px; overflow: auto;padding: 20px; box-sizing: border-box;
}
.preview-express-box img{
  border: 1px solid #eee;
}
</style>